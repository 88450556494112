<template>
  <div :class="$style.chat">
    <div ref="block" :class="$style.chat__list">
      <Message
        v-for="(msg, index) in messages"
        :key="`msg-${index}`"
        :msg="msg"
        :owner="msg.userId != null"
      />
    </div>

    <div v-if="images.length" :class="$style.chat__images">
      <div v-for="image in images" :key="image.id" :class="$style.chat__image">
        <img :class="$style.chat__image__img" :src="image.src" alt="" />
        <div :class="$style.chat__image__delete" @click="deleteImage(image.id)">
          <img src="@/assets/icons/close-green.svg" alt="delete" />
        </div>
      </div>
    </div>

    <div :class="$style.chat__wrapper">
      <Input v-model="message" :class="$style.chat__wrapper__input" @enter="sendMessage" />
      <Button :class="$style.chat__wrapper__button" type="tertiary" @click="sendMessage">
        Отправить
      </Button>
    </div>

    <Button :class="$style.chat__button" type="tertiary" @click="goBack">
      Закрыть чат <img src="@/assets/icons/save.svg" alt="save" />
    </Button>
  </div>
</template>

<script>
import Message from '@/basic/ChatMessage'
import Input from '@/basic/ChatInput'
import Button from '@/basic/Button'

import chatApi from '@/api/chat'

import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    Message,
    Input,
    Button
  },
  data() {
    return {
      message: '',
      media: []
    }
  },
  computed: {
    ...mapGetters({
      chat: 'chat',
      images: 'images',
      loading: 'loading'
    }),
    messages: {
      get() {
        return this.chat.messages ?? []
      },
      set(newName) {
        return newName
      }
    }
  },
  watch: {
    messages: {
      handler: function (messages) {
        this.messages = messages
        if (!this.loading) {
          this.scrollToBottom()
        }
      }
    },
    loading() {
      setTimeout(() => {
        this.scrollToBottom()
      }, 1000)
    }
  },
  sockets: {
    'server-messages-set'(data) {
      const { id } = this.$route.params
      if (data.chatId == id) {
        this.$socket.emit('dashboard-messages-read', { chatId: id }, (data) => {
          if (data && data.success) {
            console.log('[dashboard-messages-read]: ', data)
          }
        })
      } else {
        return
      }
    }
  },
  async mounted() {
    this.chatId = this.$route.params.id

    if (!this.loading) {
      this.scrollToBottom()
    }

    if (!this.chat.id) {
      const data = await new Promise((resolve, reject) => {
        this.$socket.emit('dashboard-chats-enter', { chatId: this.chatId }, (data) => {
          if (data && data.success) {
            resolve(data)
          } else {
            reject(data)
          }
        })
      })
      console.log('[dashboard-chats-enter]: ', data)
      this.setChat(data.result)
    }
    this.$socket.emit('dashboard-messages-read', { chatId: this.chatId }, (data) => {
      if (data && data.success) {
        console.log('[dashboard-messages-read]: ', data)
      }
    })
  },
  beforeDestroy() {
    if (this.$route.params.id || this.chat.id) {
      this.$socket.emit(
        'dashboard-chats-leave',
        {
          chatId: this.chat.id || this.$route.params.id,
          type: 'dashboard-chat-2'
        },
        () => {
          console.log('[dashboard-chats-leave]: beforeDestroy')
          this.clearChat()
        }
      )
    }
    this.$store.commit('setChatImages', [])
  },
  methods: {
    ...mapMutations(['clearChat', 'setChat']),

    async sendImages() {
      for (const img of this.images) {
        try {
          const { data } = await chatApi.sendImages(img.formData)

          if (data?.success) {
            this.media.push(data.result[0])
          }
        } catch (error) {
          console.warn(error) // eslint-disable-line

          this.$store.commit(
            'setNoticeData',
            `<p style="margin: 2rem 3rem; font-size: 14px">Ошибка загрузки файла на сервер!</p>`
          )

          this.$store.commit('setNotice', 'ErrorNotice')

          setTimeout(() => {
            this.$store.commit('setNoticeData', null)
            this.$store.commit('setNotice', null)
          }, 5000)
        }
      }
    },
    async sendMessage() {
      if (this.message || this.images.length) {
        await this.sendImages()

        this.$socket.emit(
          'dashboard-messages-set',
          {
            chatId: this.chat.id,
            text: this.message,
            media: this.media
          },
          () => {
            this.message = ''
            this.media = []
            this.$store.commit('setChatImages', [])
          }
        )
      }
    },
    scrollToBottom() {
      requestAnimationFrame(() => {
        this.$refs.block.scroll({
          top: this.$refs.block.scrollHeight,
          left: 0,
          behavior: 'smooth'
        })
      })
    },
    goBack() {
      if (this.$route.params.id || this.chat.id) {
        this.$socket.emit(
          'dashboard-chats-leave',
          {
            chatId: this.chat.id,
            type: 'dashboard-chat-1'
          },
          () => {
            this.clearChat()
            this.$router.push('/chats')
          }
        )
      }
    },
    deleteImage(id) {
      this.$store.commit('deleteChatImage', id)
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';

.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  &__list {
    overflow-x: hidden;
    height: 75vh;
    padding: 2rem 2rem 0;
    margin: 0 0 1rem;

    &::-webkit-scrollbar {
      width: 10px;
      background-color: $light-gray;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray;
    }

    &::-webkit-scrollbar-track {
      background-color: $light-gray;
      box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.2);
    }
  }

  &__images {
    display: flex;
    width: 100%;
    margin: 0 0 1rem;
  }

  &__image {
    position: relative;
    width: 5rem;
    height: 5rem;
    margin: 0 1rem 0 0;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__delete {
      position: absolute;
      top: -0.5rem;
      right: -0.75rem;
      z-index: 15;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5rem;
      height: 1.5rem;
      background: $wet-rainforest-light;
      border-radius: 50%;
      cursor: pointer;

      img {
        width: 0.6rem;
        height: 0.6rem;
      }
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__input {
      width: 85%;
    }

    &__button {
      width: 13%;
    }
  }

  &__button {
    width: 30%;
    margin: 1rem 0 0;
  }
}
</style>
